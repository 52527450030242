<template>
  <div class="policies_and_procedures lg:pt-18 lg:px-8 lg:pb-8 pt-10 px-6 pb-6 bg-darkBluePrimary" >
    <div class="lg:mb-8 mb-6">
      <h3 class="section-title mb-10 text-center text-white text-2xl">Chính sách và thủ tục</h3>
      <div class="flex flex-wrap -mx-3">
        <div v-for="(_pap, i) in paps" :key="i" class="flex-initial xl:basis-1/3 sm:basis-1/2 basis-full p-3">
          <a
            :href="_pap.url"
            class="
              bg-white
              lg:px-6 lg:py-12
              p-5
              flex flex-col
              justify-center
              items-center
              h-full
              text-textBlackPrimary
              font-bold
            "
          >
            <img :src="_pap.thumbnail" :alt="_pap.title" class="mb-4" />
            <span class="text-center text-sm">{{ _pap.title }}</span>
          </a>
        </div>
      </div>
    </div>
    <div class="maping-card bg-white">
      <div class="mapping-card-header bg-[url('/images/user/bg-mapping.png')] bg-no-repeat bg-cover p-8">
        <h3 class="section-title text-2xl">{{ $t('Khuyến nghị quy trình xuất nhập khẩu') }}</h3>
      </div>
      <div class="mapping-card-body lg:py-8 lg:px-10 p-4">
        <el-tabs :tab-position="'left'" class="mapping-tabs flex flex-wrap max-lg:flex-col max-lg:gap-y-6">
          <el-tab-pane v-for="(mapping, i) in mappings" :key="i" style="--el-tabs-header-height: unset">
            <template #label>
              <div
                class="
                  flex
                  justify-start
                  items-center
                  text-textBlackPrimary
                  before:content-['']
                  before:w-6
                  before:h-full
                  before:absolute
                  before:top-0
                  before:left-0
                  before:z-10
                  before:bg-white
                "
              >
                <div
                  class="
                    border-3
                    h-12
                    w-12
                    shrink-0
                    grow-0
                    rounded-full
                    border-[#CA192D]
                    text-xl
                    font-bold
                    flex
                    justify-center
                    items-center
                    z-10
                    bg-white
                    absolute
                    left-0
                  "
                >
                  {{ i + 1 }}
                </div>
                <div class="el-tabs__item_label flex justify-start items-center border lg:h-12 pl-12 z-0 w-full">
                  <div
                    class="
                      text-base
                      ml-2
                      lg:ml-4 lg:line-clamp-1
                      line-clamp-none
                      font-normal
                      w-full
                      whitespace-normal
                      text-left
                    "
                  >
                    {{ mapping.title }}
                  </div>
                  <div class="h-full ml-auto inline-flex items-center arrow">
                    <i class="ri-arrow-right-s-line text-bluePrimary text-2xl" />
                  </div>
                </div>
              </div>
            </template>
            <div class="p-5 lg:py-7 lg:px-8 h-full">
              <h5 class="mb-3 lg:text-xl text-xl">{{ mapping.name }}</h5>
              <!-- eslint-disable vue/no-v-html -->
              <div class="text-base whitespace-pre-line" v-html="sanitizeHtml(mapping.short_description)" />
              <!--eslint-enable-->
              <div class="mt-8">
                <div
                  v-show="mapping.link || mapping.file_url"
                  class="mb-2 text-textBlackSecondary flex justify-start items-center"
                >
                  <a
                    v-if="mapping.link"
                    class="
                      border
                      p-2
                      rounded
                      cursor-pointer
                      text-white
                      bg-greenPrimary
                      hover:bg-transparent hover:text-greenPrimary hover:border-greenPrimary
                    "
                    :href="mapping.link"
                    :target="mapping.link_type ?? '_parent'"
                  >
                    {{ $t('Xem chi tiết') }}
                  </a>
                  <a
                    v-if="mapping.file_url && !mapping.link"
                    class="
                      border
                      p-2
                      rounded
                      cursor-pointer
                      text-white
                      bg-greenPrimary
                      hover:bg-transparent hover:text-greenPrimary hover:border-greenPrimary
                    "
                    :href="mapping.file_url"
                    target="_blank"
                  >
                    {{ $t('Xem chi tiết') }}
                  </a>
                </div>
                <div class="text-base font-normal leading-tight mb-5">
                  <div v-if="mapping.url_name" class="text-textBlackSecondary mb-1">
                    {{ $t('Tên tài liệu:') }}
                    <span class="text-textBlackPrimary ml-2">{{ mapping.url_name }}</span>
                  </div>
                  <div v-if="mapping.url && !mapping.url_type" class="text-bluePrimary break-all underline">
                    <a class="underline" :href="mapping.url" target="_blank">
                      {{ mapping.url }}
                    </a>
                  </div>
                  <div v-if="mapping.file" class="flex justify-start items-start flex-wrap gap-3">
                    <span class="text-textBlackSecondary whitespace-nowrap">{{ $t('File tài liệu:') }}</span>
                    <div v-if="mapping.file_name" class="truncate max-w-[200px]" :title="mapping.file_name">
                      {{ mapping.file_name }}
                    </div>
                    <div
                      v-if="mapping.file"
                      class="text-greenPrimary flex justify-center items-center cursor-pointer"
                      @click.prevent="downloadFile(mapping.file, mapping.file_name)"
                    >
                      <svg
                        class="fill-greenPrimary w-4 h-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M13 10h5l-6 6-6-6h5V3h2v7zm-9 9h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7z" />
                      </svg>
                      <span class="ml-1">
                        {{ $t('Download') }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import sanitizeHtml from '@/plugins/sanitizeHtml'
export default {
  props: {
    mappings: { type: Object, default: () => {} },
  },
  data() {
    return {
      paps: [
        {
          title: 'Chính sách',
          thumbnail: '/images/home/chinhsach-icon.png',
          url: '/policy-and-procedures/policy?tabActive=1',
        },
        {
          title: 'Thủ tục',
          thumbnail: '/images/home/thutuc-icon.png',
          url: '/policy-and-procedures/procedures?tabActive=2',
        },
        {
          title: 'Thông tin hữu ích',
          thumbnail: '/images/home/huuich.png',
          url: '/policy-and-procedures/market-selection?tabActive=3',
        },
      ],
    }
  },
  methods: {
    sanitizeHtml,
    downloadFile(file, fileName) {
      const link = document.createElement('a')
      link.href = '/download-file?file=' + encodeURIComponent(file) + '&file_name=' + encodeURIComponent(fileName)
      link.target = '_blank'
      link.click()
    },
  },
}
</script>
<style>
.policies_and_procedures .mapping-tabs .el-tabs__header,
.policies_and_procedures .mapping-tabs .el-tabs__content {
  flex: 1;
  margin: 0;
  height: auto;
  float: unset;
}

.policies_and_procedures .el-tabs__item {
  height: unset;
  line-height: unset;
  padding: 0;
  margin-top: 32px;
}

.policies_and_procedures .el-tabs__active-bar.is-left,
.policies_and_procedures .el-tabs--left .el-tabs__nav-wrap.is-left::after {
  display: none;
}

.policies_and_procedures .el-tabs__item:nth-child(2) {
  margin-top: unset;
}

.policies_and_procedures .el-tab-pane[aria-hidden='false'] {
  background-color: rgb(231 241 250);
  height: 100%;
}

.policies_and_procedures .el-tabs__item.is-left.is-active .el-tabs__item_label {
  background-color: rgb(231 241 250);
  border-color: rgb(231 241 250);
}

.policies_and_procedures .el-tabs__item.is-left.is-active .arrow {
  background-color: rgb(231 241 250);
}

.policies_and_procedures .el-tabs__item.is-left:not(.is-active) .el-tabs__item_label {
  margin-right: 12px;
}
@media screen and (max-width: 767px) {
  .policies_and_procedures .el-tabs--left .el-tabs__header.is-left {
    width: 100%;
    margin: 0 0 20px;
  }

  .policies_and_procedures .el-tabs__content {
    width: 100%;
    margin: 0;
  }

  .policies_and_procedures .el-tabs__item {
    margin-top: 10px;
  }

  .policies_and_procedures .el-tabs__item.is-left:not(.is-active) .el-tabs__item_label {
    margin-right: 0;
  }
  .policies_and_procedures .el-tabs__item.is-left .el-tabs__item_label {
    margin: 10px 0;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .policies_and_procedures .el-tabs__item.is-left .el-tabs__item_label {
    margin: 10px 0;
  }
}
</style>
